export const theme = {
    colors: {
        turquoise: '#3FBCCE', // previous - '#4ad0ee',
        lightTurquoise: '#b7ecf8',
        golden: '#FEC400',
        green: '#4caf50',
        coral: '#f44336',
        darkGrey: '#5f6985',
        lightGrey: '#878EA0',
        white: '#ffffff',
        black: '#000000',
        danger: '#f43f5e',
        success: '#4caf50',
        warning: '#ffc107',
        offWhite: '#FDFDFC',
        lighterTeal: '#2A6F7A',
        lightTeal: '#034752',
        mint: '#6ED6A3',
        orange: '#F07144',
        darkBlue: '#121E3F',
        midTeal: '#0B363F',
    },
    backgrounds: {
        bg100: 'linear-gradient(167.96deg, #102930 0%, #0b1d22 49.79%, #081417 100%)',
        bg200: '#0d1a46',
        bg300: '#14224f',
        bg400: '#293560',
        bg500: '#245e7d',
        bg200Alpha: 'rgba(13, 26, 70, 0.6)',
        stroke: 'linear-gradient(134deg, #081417 16.55%, #102930 86.38%)',
        toastBg: '#455282',
        avatarBg: '#7481ae',
        toastError:
            'linear-gradient(0deg, #293560, #293560), linear-gradient(90deg, rgba(244, 67, 54, 0.2) 0%, rgba(244, 67, 54, 0) 100%)',
        backdrop: 'linear-gradient(167.96deg, #102930d9 0%, #0b1d22d9 49.79%, #081417d9 100%)',
    },
    button: {
        bg: '#3FBCCE',
        shadow: '0px 10px 16px 0px #4AD0EE29',
        centerButton: {
            overlay: 'linear-gradient(0deg, #081417 0%, rgba(16, 41, 48, 0.55) 66.15%, rgba(9, 15, 38, 0) 100%)',
            disabled: '#878EA0',
        },
    },
    opacity: {
        disabled: 0.5,
    },
    typography: {
        font1: "Manrope, 'Fira Sans', sans-serif",
        font2: "'Source Sans Pro', sans-serif",
        font3: 'Sono, sans-serif',
        fontWeight: {
            400: 400,
            500: 500,
            600: 600,
            700: 700,
        },
    },
};
