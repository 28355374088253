import { createGlobalStyle } from 'styled-components';
import { cardStyles } from './cardStyles';
import { buttonStyles } from './buttonStyles';
import { homePageStyles } from './homePageStyles';
import { eventStyles } from './eventCalenderStyles';
import { formStyles } from './formStyles';
import { customStyles } from './customStyles';
import { inputOutputCheckPageStyle } from './inputOutputCheckPageStyle';
import { bottomSheetStyle } from './bottomSheetStyle';
import { volumeSliderContainerStyle } from './volumeSliderContainerStyle';
import { notchModalStyle } from './notchModalStyle';
import { inviteParticipantsStyle } from './inviteParticipantsStyle';
import { toastStyle } from './toastStyle';
import { waitingScreenStyle } from './waitingScreenStyle';
import { avatarStyles } from './avatarStyle';
import { bookedConnection } from './bookedConnection';
import { verificationCodeStyle } from './verificationCodeStyle';
import { controlsStyle } from './controlsStyle';

const GlobalStyle = createGlobalStyle`
${customStyles}
${formStyles}
${homePageStyles}
${cardStyles}
${buttonStyles}
${eventStyles}
${inputOutputCheckPageStyle}
${bottomSheetStyle}
${volumeSliderContainerStyle}
${notchModalStyle}
${inviteParticipantsStyle}
${toastStyle}
${waitingScreenStyle}
${avatarStyles}
${bookedConnection}
${verificationCodeStyle}
${controlsStyle}
`;

export default GlobalStyle;
