import { css } from 'styled-components';

//* Custom styles (_custom.scss) *//
export const customStyles = css`
    body {
        color: ${({ theme }) => theme.colors.darkGrey} !important;
        background: ${({ theme }) => theme.backgrounds.bg100};
        /* prevent pull-to-refresh for Chrome 63+ */
        overscroll-behavior-y: none;
    }

    h1 {
        //32px/38.4px
        font: normal ${({ theme }) => theme.typography.fontWeight[700]} 2rem/2.4rem
            ${({ theme }) => theme.typography.font1} !important;
        letter-spacing: 0.005em;
    }

    h2 {
        //28px/34px
        font: normal ${({ theme }) => theme.typography.fontWeight[700]} 1.75rem/2.125rem
            ${({ theme }) => theme.typography.font1};
        letter-spacing: 0.005em;
    }

    h3 {
        //24px/29.008px
        font: normal ${({ theme }) => theme.typography.fontWeight[700]} 1.5rem/1.813rem
            ${({ theme }) => theme.typography.font1};
        letter-spacing: 0.005em;
    }

    h4 {
        //20px/24px
        font: normal ${({ theme }) => theme.typography.fontWeight[700]} 1.25rem/1.5rem
            ${({ theme }) => theme.typography.font1};
        letter-spacing: 0.005em;
    }

    h5 {
        //20px/24px
        font: normal ${({ theme }) => theme.typography.fontWeight[600]} 1.25rem/1.5rem
            ${({ theme }) => theme.typography.font1};
        letter-spacing: 0.005em;
    }

    h6 {
        //18px/20px
        font: normal ${({ theme }) => theme.typography.fontWeight[400]} 1.125rem/1.25rem
            ${({ theme }) => theme.typography.font1};
        letter-spacing: 0.005em;
    }

    .h6-custom {
        //18px/20px
        font: normal ${({ theme }) => theme.typography.fontWeight[600]} 1.125rem/1.35rem
            ${({ theme }) => theme.typography.font1};
        letter-spacing: 0.005em;
    }

    .b1 {
        //18px/28px
        font: normal ${({ theme }) => theme.typography.fontWeight[600]} 1.125rem/1.75rem
            ${({ theme }) => theme.typography.font1};
        letter-spacing: 0.002em;
    }

    .b2 {
        //16px/26px
        font: normal ${({ theme }) => theme.typography.fontWeight[600]} 1rem/1.625rem
            ${({ theme }) => theme.typography.font2};
        letter-spacing: 0.002em;
    }

    .b3 {
        //16px/26px
        font: normal ${({ theme }) => theme.typography.fontWeight[400]} 1rem/1.625rem
            ${({ theme }) => theme.typography.font3};
        letter-spacing: 0.002em;
        // color: $light-grey;
    }

    .b4 {
        //14px/22px
        font: normal ${({ theme }) => theme.typography.fontWeight[400]} 0.875rem/1.375rem
            ${({ theme }) => theme.typography.font2};
        letter-spacing: 0.002em;
    }

    .b5 {
        //12px/20px
        font: normal ${({ theme }) => theme.typography.fontWeight[400]} 0.75rem/1.25rem
            ${({ theme }) => theme.typography.font2};
        letter-spacing: 0.002em;
    }

    .b6 {
        //16px/24px
        font: normal ${({ theme }) => theme.typography.fontWeight[600]} 1rem/1.5rem
            ${({ theme }) => theme.typography.font1};
        letter-spacing: 0.02em;
    }

    .b7 {
        //18px/22px
        font: normal ${({ theme }) => theme.typography.fontWeight[400]} 1.125rem/1.375rem
            ${({ theme }) => theme.typography.font1};
        letter-spacing: 0.005em;
    }

    .end-connection-button {
        background-color: ${({ theme }) => theme.colors.orange} !important;
    }

    .icon-active {
        &::before {
            color: ${({ theme }) => theme.colors.white};
        }

        &.icon-logout::before {
            margin-left: 0.3rem;
        }
    }

    .icon-disabled,
    .icon-default {
        &::before {
            color: ${({ theme }) => theme.colors.lightGrey};
        }

        &.icon-logout::before {
            margin-left: 0.3rem;
        }

        &.disabled-white {
            &::before {
                color: ${({ theme }) => theme.colors.white};
            }
        }
    }

    .icon-enabled {
        &::before {
            color: ${({ theme }) => theme.colors.turquoise};
        }

        &.icon-logout::before {
            margin-left: 0.3rem;
        }
    }

    .icon-warning {
        &::before {
            color: ${({ theme }) => theme.colors.golden};
        }

        &.icon-logout::before {
            margin-left: 0.3rem;
        }
    }
`;
