import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const createSessionAndInvitePortalAPI = async ({
    orgId,
    portalId,
    selectedPortal,
    securityAuthToken,
    duration,
}) => {
    // const securityToken = sessionStorage.getItem('securityToken');
    const body = {
        ...selectedPortal,
        isControllerUsage: true,
        'x-portals-controller': true,
        sessionDuration: duration,
    };
    if (securityAuthToken) {
        body['x-security-token'] = securityAuthToken;
    }
    return await axios
        .post(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/create-and-invite`, body)
        .then((res) => {
            return res.data;
        });
};
