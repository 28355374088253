import { theme } from '../styles/theme';

export const INV_PARTICIPANTS_TOAST_ID = 'customToastForInvParticipantId';

// Participant States
export const STATE_IDLE = 'STATE_IDLE';
export const STATE_JOINING = 'STATE_JOINING';
export const STATE_JOINED = 'STATE_JOINED';
export const STATE_LEAVING = 'STATE_LEAVING';
export const STATE_ERROR = 'STATE_ERROR';
export const STATE_HAIRCHECK = 'STATE_HAIRCHECK';

// Portal States
export const PORTAL_ONLINE = 'online';
export const PORTAL_IN_CALL = 'in-call';
export const PORTAL_BOOKED = 'booked';
export const PORTAL_OFFLINE = 'offline';
export const PORTAL_IN_MAINTENANCE = 'in-maintenance';
export const PORTAL_DISCONNECTED = '-'; //disconnected
export const PORTAL_PENDING = 'pending';
export const PORTAL_ERROR = 'error';
export const PORTAL_ARCHIVED = 'archived';
export const PORTAL_ACTIVE = 'active';

// Portal states sorting order
export const statusOrder = {
    [PORTAL_ONLINE]: 1,
    [PORTAL_IN_CALL]: 2,
    [PORTAL_BOOKED]: 3,
    [PORTAL_OFFLINE]: 4,
    [PORTAL_IN_MAINTENANCE]: 5,
    [PORTAL_DISCONNECTED]: 6,
    [PORTAL_PENDING]: 7,
    [PORTAL_ERROR]: 8,
    [PORTAL_ARCHIVED]: 9,
};

const { colors } = theme;

const $green = colors.mint;
const $coral = colors.coral;
const $warning = colors.warning;
const $turquoise = colors.turquoise;

export const portalState = {
    [PORTAL_ONLINE]: ['Available', $green, 'available'],
    [PORTAL_IN_CALL]: ['In Connection', $turquoise, PORTAL_IN_CALL],
    [PORTAL_BOOKED]: ['Booked', $warning, 'warning'],
    [PORTAL_OFFLINE]: ['Offline', $coral, PORTAL_OFFLINE],
    [PORTAL_IN_MAINTENANCE]: ['In Maintenance', $coral, PORTAL_IN_MAINTENANCE],
    [PORTAL_DISCONNECTED]: ['Disconnected', $coral, PORTAL_DISCONNECTED],
    [PORTAL_PENDING]: ['Pending', $coral, PORTAL_PENDING],
    [PORTAL_ERROR]: ['Error', $coral, PORTAL_ERROR],
};

export const statusMap = {
    [PORTAL_ONLINE]: { text: 'Available', className: 'available-color' },
    [PORTAL_OFFLINE]: { text: 'Offline', className: 'offline-color' },
    [PORTAL_BOOKED]: { text: 'Booked', className: 'booked-color' },
    [PORTAL_IN_CALL]: { text: 'In Connection', className: 'invited-color' },
    [PORTAL_IN_MAINTENANCE]: { text: 'In Maintenance', className: 'in-maintenance-color' },
};

// Connection States
export const CONNECTION_CREATED = 'created';
export const CONNECTION_ONGOING = 'ongoing';
export const CONNECTION_CANCELLED = 'cancelled';
export const CONNECTION_ENDED = 'ended';

// Controller Screenshare States
export const START_SCREENSHARE = 'start_screenshare';
export const STOP_SCREENSHARE = 'stop_screenshare';
export const LOADING_SCREENSHARE = 'loading_screenshare';

// portal states
export const PORTAL_STATE = {
    PENDING: 'pending',
    ARCHIVED: 'archived',
    DISCONNECTED: 'disconnected',
    ACTIVE: 'active',
    ERROR: 'error',
    IN_MAINTENANCE: 'in-maintenance',
};

// Connection state
export const CONNECTION_STATE = {
    AVAILABLE: 'online',
    OFFLINE: 'offline',
    BOOKED: 'booked',
    IN_CONNECTION: 'in-call',
    INVALID: '-',
};

export const ONE_SECOND_IN_MS = 1000; // 1000 milliseconds in a second
export const ONE_MINUTE_IN_MS = 60 * ONE_SECOND_IN_MS; // 60 seconds in a minute, 1000 milliseconds in a second
export const FIVE_MINUTE_IN_MS = 5 * ONE_MINUTE_IN_MS; // 5 minutes to milliseconds

export const TEAMS_WAITING_TIMEOUT = 60; // waiting screen timer during teams connection
