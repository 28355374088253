import { useEffect } from 'react';
import { getListOfPortals } from '../redux-toolkit/reducer/portalListReducer';
import { useDispatch } from 'react-redux';
import ablyMessages from '../utils/ablyMessages';

export default function usePortalRegisterORUpdatedHook({ message }) {
    const dispatch = useDispatch();

    const handlePortalChanges = async (commandData, delay = 0) => {
        if (delay > 0) {
            setTimeout(async () => {
                await dispatch(getListOfPortals({ orgId: commandData.org_id, limit: 1000 }));
            }, delay);
        } else {
            await dispatch(getListOfPortals({ orgId: commandData.org_id, limit: 1000 }));
        }
    };

    useEffect(() => {
        const handleCommand = async () => {
            const command = message?.data?.message || '';
            const commandData = message?.data?.data || {};

            if (command === ablyMessages.portal.newPortalRegistered) {
                await handlePortalChanges(commandData, 10000); // Delay of 10 seconds
            } else if (command === ablyMessages.portal.portalAssignedGroupUpdated) {
                await handlePortalChanges(commandData); // No delay
            }
        };

        handleCommand();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message, dispatch]);
}
