import { css } from 'styled-components';

export const toastStyle = css`
    .toast {
        max-width: 100%;
        width: 23.438rem;
        border-radius: 1rem;
        background: ${({ theme }) => theme.backgrounds.toastBg};
        color: ${({ theme }) => theme.colors.white};
        font: normal ${({ theme }) => theme.typography.fontWeight[400]} 0.875rem/1.375rem
            ${({ theme }) => theme.typography.font2};
        letter-spacing: 0.002em;

        .btn-container {
            font-size: 1.094rem;
            align-self: center !important;
        }

        .body {
            padding-right: 0.5rem !important;
        }

        &.error {
            background: ${({ theme }) => theme.colors.coral};

            .body {
                padding-left: 0.5rem !important;
                padding-right: 0rem !important;
                flex-grow: 1;
            }
        }
    }

    :root {
        --toastify-toast-width: 16.125rem !important;
        --bs-font-sans-serif: ${({ theme }) => theme.typography.font2};
        --toastify-toast-min-height: 60px;
        --toastify-toast-max-height: 60px;
        --toastify-toast-background: linear-gradient(90deg, rgba(76, 175, 80, 0.2) 0%, rgba(76, 175, 80, 0) 100%),
            #293560;
        --toastify-color-light: linear-gradient(90deg, rgba(76, 175, 80, 0.2) 0%, rgba(76, 175, 80, 0) 100%), #293560;
    }

    .Toastify {
        .Toastify__toast-container {
            font-family: var(--bs-font-sans-serif);
            width: var(--toastify-toast-width);
            min-height: var(--toastify-toast-min-height);
            max-height: var(--toastify-toast-max-height);
            left: 50%;
            transform: translateX(-50%);
            bottom: 2rem;

            .Toastify__toast {
                border-radius: 0.5rem;
                padding: 1rem !important;

                .Toastify__close-button {
                    opacity: 1;
                    color: ${({ theme }) => theme.colors.turquoise};
                }

                .Toastify__toast-body {
                    padding: 0 !important;

                    .toast-custom-container {
                        align-items: center;

                        .msg {
                            color: ${({ theme }) => theme.colors.white};
                            padding-left: 0.5rem;
                        }

                        .custom-toast-text-btn-div {
                            width: 12.5rem;
                        }
                    }
                }
            }

            .Toastify__toast-theme--light {
                background: linear-gradient(90deg, rgba(76, 175, 80, 0.2) 0%, rgba(76, 175, 80, 0) 100%), #293560 !important;
            }
        }
    }

    div#ct-container {
        min-height: var(--toastify-toast-min-height);
        color: ${({ theme }) => theme.colors.white};
        font: normal ${({ theme }) => theme.typography.fontWeight[400]} 0.875rem/1.375rem
            ${({ theme }) => theme.typography.font2};
        letter-spacing: 0.002em;
        top: -6.575% !important;

        .ct-group {
            .ct-toast {
                background: ${({ theme }) => theme.backgrounds.bg100};
                width: var(--toastify-toast-width);
                min-height: var(--toastify-toast-min-height);
                border-radius: 0.5rem;

                .ct-text-group {
                    margin-left: 0px;
                }

                .toast-custom-container {
                    align-items: center;

                    .msg {
                        color: ${({ theme }) => theme.colors.white};
                        padding: 0.5rem;
                        font-family: ${({ theme }) => theme.typography.font2};
                        font-weight: ${({ theme }) => theme.typography.fontWeight[400]};
                    }

                    .custom-toast-text-btn-div {
                        width: 12.5rem;
                    }
                }

                &.ct-toast-success {
                    position: relative;
                    border: none !important;
                    margin-bottom: 0px !important;
                    top: 4rem;

                    > .icon-close {
                        position: absolute;
                        left: calc(50% + 6.5rem);
                        top: 0.5rem;
                        font-size: 1rem;

                        &::before {
                            color: ${({ theme }) => theme.colors.turquoise};
                        }
                    }
                }
            }
        }
    }
`;
