import { css } from 'styled-components';
import downArrow from '../assets/icons/down.svg';

export const inputOutputCheckPageStyle = css`
    .main-container {
        display: flex;
        align-items: center;
        flex: 1;
        position: relative;
        left: 0;
        bottom: 0;
        flex-direction: column;
        // align-items: flex-start;
        gap: 40px;

        .control-container {
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: center;
            gap: 32px;
            align-self: stretch;
            border-radius: 8px;
            background: ${({ theme }) => theme.colors.lightTeal};

            .text-container {
                width: 273px;
                color: ${({ theme }) => theme.colors.offWhite};
                text-align: center;
                font-family: ${({ theme }) => theme.typography.font1};
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                letter-spacing: 0.036px;
            }
            .play-sound-button {
                display: flex;
                padding: 12px 30px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 24px;
                border: 1px solid ${({ theme }) => theme.colors.turquoise};
                box-shadow: 0px 10px 16px 0px rgba(74, 208, 238, 0.16);
                background-color: transparent;

                .button-text {
                    color: ${({ theme }) => theme.colors.turquoise};
                    text-align: center;
                    font-family: ${({ theme }) => theme.typography.font1};
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.32px;
                }
            }
            .volume-button {
                width: 296px;
                height: 60px;
                padding: 0px 23px;
                align-items: center;
            }
        }

        .join-connection-input-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 3px;
            position: relative; //! check if working
            align-self: stretch; //! check if working
        }

        .join-connection-input-container > label {
            margin: 0px 20px;
            font-family: ${({ theme }) => theme.typography.font1};
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.002em;
            color: ${({ theme }) => theme.colors.offWhite};
        }

        .join-connection-input-container input,
        select {
            padding: 16px 20px;
            border: 2px solid ${({ theme }) => theme.backgrounds.bg500};
            background: ${({ theme }) => theme.backgrounds.stroke};
            border-radius: 32px;
            color: ${({ theme }) => theme.colors.white};
            font-family: ${({ theme }) => theme.typography.font2};
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;
            letter-spacing: 0.002em;
        }

        .join-connection-input-container > select {
            padding-right: 2.5rem;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url(${downArrow});
            background-repeat: no-repeat;
            background-position: right 1.5rem center;
        }
        .join-connection-input-container > select > option {
            background: ${({ theme }) => theme.colors.lightTeal};
            padding: 0px;
        }

        .join-connection-input-container select:focus {
            background-color: transparent;
            outline: none;
        }

        .selection-input {
            display: inline-block;
            position: relative;
            > input {
                padding-right: 2.5rem;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url(${downArrow});
                background-repeat: no-repeat;
                background-position: right 1rem center;
                cursor: pointer;
            }
        }
        .connect-button {
            display: flex;
            padding: 12px 30px;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            border-radius: 24px;
            background: ${({ theme }) => theme.colors.turquoise};
            border: 1px solid ${({ theme }) => theme.colors.turquoise};
            box-shadow: 0px 10px 16px 0px rgba(74, 208, 238, 0.16);

            .connect-button-text {
                color: ${({ theme }) => theme.colors.white};
                text-align: center;
                font-family: ${({ theme }) => theme.typography.font2};
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.32px;
            }
        }
    }
    .verification-cancel {
        position: absolute;
        top: 24px;
        left: 16px;
        z-index: 10;
    }

    select {
        padding: 16px 20px;
        border: 2px solid ${({ theme }) => theme.backgrounds.bg500};
        background: ${({ theme }) => theme.backgrounds.stroke};
        border-radius: 32px;
        color: ${({ theme }) => theme.colors.white};
        font-family: ${({ theme }) => theme.typography.font2};
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.002em;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url(${downArrow});
        background-repeat: no-repeat;
        background-position: right 1rem center;
        cursor: pointer;
    }

    select option {
        background: ${({ theme }) => theme.colors.lightTeal};
        padding: 8px 16px;
    }

    select option:hover {
        background: ${({ theme }) => theme.colors.lighterTeal} !important;
        color: ${({ theme }) => theme.colors.white};
    }

    select:focus {
        outline: none;
        border-color: ${({ theme }) => theme.colors.lighterTeal};
    }
`;
