import { css } from 'styled-components';

//* Home page styles (HomePages.jsx) *//
export const homePageStyles = css`
    .container-fluid {
        background: none;

        .invited-card-home {
            width: 100% !important;
            margin-top: 3rem;
            margin-bottom: 1.4rem;
        }

        .card-with-icon {
            width: 100%;
            height: 11.875rem;
            margin: 4.55rem 0 2.55rem 0;
            background: ${({ theme }) => theme.typography.fontWeight[600]};
            border-radius: 1rem;

            .btn-circle {
                position: absolute;
                left: 0;
                right: 0;
                top: 7rem;
                /* bottom: 66.1%; */
                z-index: 1;
                pointer-events: none;
                margin: 0 auto;
            }

            .inner-card {
                position: absolute;
                left: 50%;
                transform: translate(-50%);

                > .inner-text {
                    color: ${({ theme }) => theme.colors.white};
                    text-align: center;
                    margin-top: 3.7rem;
                }

                .btn-sm1 {
                    margin-top: 1.875rem;
                }
            }

            .invited-inner-card {
                position: relative;
                left: 0;
                transform: none;
                width: 273px;
                padding-bottom: 1.875rem;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            &.extended-height {
                height: 20.275rem;
                .or-text {
                    text-align: center;
                    margin-top: 1rem;
                    color: ${({ theme }) => theme.colors.white};
                }
            }
            &.no-btn-height {
                height: 7.875rem;
            }
            .btn-loading {
                border: 4px solid ${({ theme }) => theme.backgrounds.bg300};
                background: #0c1433;
                .spinner-bg {
                    .spinner {
                        height: 2rem;
                        width: 2rem;
                        background-size: 100% 100%;
                    }
                }
            }
        }

        .invited-card {
            height: max-content;
        }
    }

    /* New Home Page Styles */
    .portal-status {
        font-weight: ${({ theme }) => theme.typography.fontWeight[400]};
        font-family: ${({ theme }) => theme.typography.font3};
        font-size: 1rem;
        letter-spacing: 0.002em;
        color: ${({ theme }) => theme.colors.lightGrey};
    }

    .availability-info {
        font-size: 1rem;
        color: ${({ theme }) => theme.colors.lightGrey};
        letter-spacing: 0.002em;
        font-family: ${({ theme }) => theme.typography.font2};
        font-weight: ${({ theme }) => theme.typography.fontWeight[600]};
    }

    .control-container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .invited-event {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        background-color: ${({ theme }) => theme.backgrounds.bg500};
        margin: 0 1rem 1rem 1rem;
        border-radius: 1.5rem;
        font-family: ${({ theme }) => theme.typography.font1};

        > p {
            padding: 0;
            margin: 0;
            color: ${({ theme }) => theme.colors.white};
            font-weight: ${({ theme }) => theme.typography.fontWeight[600]};
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.005em;

            > span {
                color: ${({ theme }) => theme.colors.turquoise};
            }
        }

        > button {
            background-color: ${({ theme }) => theme.colors.white};
            outline: none;
            border: none;
            color: ${({ theme }) => theme.backgrounds.bg400};
            border-radius: 1.5rem;
            padding: 12px 0px;
            width: 215px;
        }
    }

    .start-con-skeleton {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1.5rem;
        height: 48px;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.lighterTeal};
    }
`;
