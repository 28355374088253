import { css } from 'styled-components';

//* Button styles (_button.scss) *//
export const buttonStyles = css`
    button {
        // 16px/24px 600
        font: normal ${({ theme }) => theme.typography.fontWeight[600]} 1rem/1.5rem
            ${({ theme }) => theme.typography.font1};
        letter-spacing: 0.02em;
    }

    .full-width-btn-container {
        width: 100%;
    }

    .btn {
        background: transparent;
        border: 2px solid ${({ theme }) => theme.colors.turquoise};
        border-radius: 2rem;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        color: ${({ theme }) => theme.colors.turquoise};
        font: normal ${({ theme }) => theme.typography.fontWeight[600]} 1rem/1.5rem
            ${({ theme }) => theme.typography.font1};
        width: 100%;

        &.btn-danger {
            background-color: ${({ theme }) => theme.colors.orange};
            border: 2px solid ${({ theme }) => theme.colors.orange} !important;
            color: ${({ theme }) => theme.colors.offWhite};

            &:hover {
                color: ${({ theme }) => theme.colors.offWhite};
            }
        }

        &:hover {
            color: ${({ theme }) => theme.colors.turquoise};
        }

        &.btn-sm {
            padding: 0.363rem 1.25rem;
        }

        &.btn-lg {
            //height: 84px
            padding: 1.75rem 5.25rem;
        }

        &.btn-md {
            //height: 64px
            padding: 1.125rem 1.25rem;
            width: 16rem; //256px
        }

        &.btn-sm1 {
            //height: 48px
            padding: 0.625rem 1.25rem;
            width: 17.063rem; //273px
        }

        &.btn-sm2 {
            padding: 0.75rem;
        }

        &.btn-width {
            width: fit-content;
            box-shadow: none !important;
            padding: 1rem 3rem;
        }

        &.btn-light-grey {
            color: ${({ theme }) => theme.colors.lightGrey};
            border-color: ${({ theme }) => theme.colors.lightGrey};
        }

        &.btn-primary {
            background: ${({ theme }) => theme.colors.turquoise};
            box-shadow: 0px 10px 16px rgba(74, 208, 238, 0.16);
            color: ${({ theme }) => theme.colors.white} !important;

            &:hover {
                background: ${({ theme }) => theme.colors.turquoise};
                box-shadow: 0px 10px 16px rgba(74, 208, 238, 0.16);
                color: ${({ theme }) => theme.colors.white} !important;
                border: 2px solid ${({ theme }) => theme.colors.turquoise};
            }

            &:disabled {
                background: ${({ theme }) => theme.colors.lightGrey};
                border: none;
                opacity: 1;
                box-shadow: none;
            }

            &.disabled {
                background: ${({ theme }) => theme.colors.turquoise};
                border: 2px solid ${({ theme }) => theme.colors.turquoise};
                opacity: 0.8;
            }
        }

        &.btn-warning {
            background: ${({ theme }) => theme.colors.golden} !important;
            border: 2px solid ${({ theme }) => theme.colors.golden} !important;
            color: ${({ theme }) => theme.colors.white};

            &:hover {
                background: ${({ theme }) => theme.colors.golden} !important;
                color: ${({ theme }) => theme.colors.white} !important;
                border: 2px solid ${({ theme }) => theme.colors.turquoise};
            }

            &:disabled {
                background: ${({ theme }) => theme.colors.golden} !important;
                color: ${({ theme }) => theme.colors.white} !important;
                opacity: 0.8;
            }
        }

        &.btn-disabled {
            background: ${({ theme }) => theme.colors.lightGrey};
            color: ${({ theme }) => theme.colors.white} !important;
            border: 2px solid ${({ theme }) => theme.colors.lightGrey};
            pointer-events: none;

            &:hover {
                background: ${({ theme }) => theme.colors.lightGrey};
                color: ${({ theme }) => theme.colors.white} !important;
                border: 2px solid ${({ theme }) => theme.colors.lightGrey};
                pointer-events: none;
            }
        }

        &.btn-info {
            background: ${({ theme }) => theme.backgrounds.bg300};
            box-shadow: none;
        }

        &.btn-fixed {
            width: 17.563rem;
        }

        &.btn-bottom-align {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 4rem;
        }
    }

    .btn-circle {
        height: 5rem;
        width: 5rem;
        border: none;
        border-radius: 50%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        i {
            font-size: 2rem;

            @media (max-width: 374px) {
                font-size: 1.25rem;
            }
        }

        &.active {
            background: ${({ theme }) => theme.colors.turquoise};
            box-shadow: 0px 10px 16px rgba(74, 208, 238, 0.16);
        }

        &.enabled {
            background: ${({ theme }) => theme.backgrounds.bg100};
            border: 2px solid ${({ theme }) => theme.backgrounds.bg500} !important;
            &.mute {
                border: 2px solid ${({ theme }) => theme.colors.orange} !important;
                .icon-mute::before {
                    color: ${({ theme }) => theme.colors.orange} !important;
                }
            }
        }

        &.disabled {
            background: ${({ theme }) => theme.backgrounds.stroke};
            border: 2px solid ${({ theme }) => theme.backgrounds.bg500} !important;
        }

        &.disabled-grey {
            background: ${({ theme }) => theme.backgrounds.stroke};
            border: 2px solid ${({ theme }) => theme.colors.lightGrey} !important;
        }

        &.btn-circle-sm1 {
            height: 3.75rem; //60px
            width: 3.75rem;
            border: none;

            i {
                font-size: 2rem;
            }
        }

        &.btn-circle-sm2 {
            height: 2.75rem; //44px
            width: 2.75rem;
            border: none;

            i {
                font-size: 1.5rem;
            }
        }

        &.btn-circle-sm3 {
            height: 3.313rem; //53.008px
            width: 3.313rem;
            border: none;

            i {
                font-size: 2rem;
            }
        }

        &.warning {
            background-color: ${({ theme }) => theme.colors.golden};
            &.disabled {
                pointer-events: none;
                background: ${({ theme }) => theme.colors.golden} !important;
                opacity: ${({ theme }) => theme.opacity.disabled};
            }
        }

        &.available {
            background-color: ${({ theme }) => theme.colors.success};
        }

        &.in-call,
        &.invited {
            background-color: ${({ theme }) => theme.colors.turquoise};
        }

        &.offline {
            background-color: ${({ theme }) => theme.colors.coral};
        }

        &.pending-activation {
            background-color: ${({ theme }) => theme.colors.warning};
        }

        &.booked {
            background-color: ${({ theme }) => theme.colors.golden};
        }

        &.in-maintenance {
            background-color: ${({ theme }) => theme.colors.lightGrey};
        }

        @media (max-width: 374px) {
            height: 3.75rem;
            width: 3.75rem;
        }
    }

    .btn-circle-hidden-invite {
        height: 5rem;
        width: 5rem;
        border: none;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        i {
            font-size: 2rem;
        }

        &.active {
            background: ${({ theme }) => theme.colors.turquoise};
            box-shadow: 0px 10px 16px rgba(74, 208, 238, 0.16);
        }

        &.disabled {
            background: ${({ theme }) => theme.backgrounds.stroke};
            border: 2px solid ${({ theme }) => theme.backgrounds.bg500} !important;
        }
    }

    /* Additional styles for better visibility on mobile devices */
    @media only screen and (max-width: 767px) {
        .btn-circle-hidden-invite {
            height: 4rem;
            width: 4rem;
            font-size: 16px;
            i {
                font-size: 1.25rem;
            }
        }
    }
    /* Targeting smaller mobile devices */
    @media only screen and (max-width: 320px) {
        .btn-circle-hidden-invite {
            height: 3rem;
            width: 3rem;
            font-size: 16px;
            i {
                font-size: 1rem;
            }
        }

        .invite-button {
            font-size: 14px;
            padding: 10px !important;
            width: 10rem !important;
        }
    }

    .invite-button {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        background: linear-gradient(0deg, #090f26 0%, rgba(9, 15, 38, 0.552083) 66.15%, rgba(9, 15, 38, 0) 100%);
    }

    .center-btn-containter {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 116px;
        background: linear-gradient(0deg, #090f26 0%, rgba(9, 15, 38, 0.552083) 66.15%, rgba(9, 15, 38, 0) 100%);
    }

    .record-btn-disabled {
        height: 5rem;
        width: 5rem;
        border: none;
        border-radius: 50%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        i {
            font-size: 2rem;

            @media (max-width: 374px) {
                font-size: 1.25rem;
            }
        }
    }
`;
