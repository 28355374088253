import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getListOfPortalsAPI } from '../../api/getListOfPortalsAPI';

const initialState = {
    loading: false,
    error: false,
    errorObj: {},
    invitePortal: false,
    title: 'Which portal are you in?',
    portalList: [],
    portalListRaw: [],
};

export const getListOfPortals = createAsyncThunk('portals/getListOfPortals', async (orgPortal) => {
    const response = await getListOfPortalsAPI(orgPortal);
    return response.data;
});

const portalListSlice = createSlice({
    name: 'portals',
    initialState,
    reducers: {
        // this is to just manipulate state object
        setInvitePortal(state, action) {
            state.invitePortal = action.payload;
        },
        setPortalList(state, action) {
            const newOrgList = {
                data: action.payload,
                pager: action.payload.pager,
            };
            state.portalList = newOrgList;
        },
        setTitle(state, action) {
            state.title = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListOfPortals.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
            })
            .addCase(getListOfPortals.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                const list = [...action.payload.data];
                const newOrgList = {
                    data: list,
                    pager: action.payload.pager,
                };
                state.portalListRaw = newOrgList;
            })
            .addCase(getListOfPortals.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
                state.portalList = { data: [], pager: {} };
            });
    },
});

export const { setInvitePortal, setTitle, setPortalList } = portalListSlice.actions;
export default portalListSlice.reducer;
