import { AblyProvider, ChannelProvider } from 'ably/react';
import useAblyClient from '../hooks/useAblyClient';
import { AblyStateProvider } from './AblyStateProvider';

function AblyClientProvider({ children }) {
    const ablyClient = useAblyClient();

    return ablyClient ? (
        <AblyProvider client={ablyClient}>
            <ChannelProvider channelName="portals" options={{ modes: ['PRESENCE_SUBSCRIBE', 'PUBLISH', 'SUBSCRIBE'] }}>
                <AblyStateProvider>{children}</AblyStateProvider>
            </ChannelProvider>
        </AblyProvider>
    ) : (
        children
    );
}

export { AblyClientProvider };
