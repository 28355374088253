import { css } from 'styled-components';

export const verificationCodeStyle = css`
    .verification-code {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .verification-cancel {
        position: absolute;
        top: 24px;
        left: 16px;
        z-index: 10;
    }

    .verification-code-container {
        width: 100%;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.midTeal};
        padding: 2rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // gap: 0.5rem;

        > .verified-check {
            position: relative;
            width: 44px;
            > div {
                position: absolute;
                bottom: 100%;
                margin-bottom: 0.5rem;
                background-color: ${({ theme }) => theme.colors.green};
                height: 44px;
                width: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;

                > svg {
                    color: ${({ theme }) => theme.colors.black};
                }
            }
        }

        > .code-verified-message {
            margin: 0;
            padding: 0;
            font-family: ${({ theme }) => theme.typography.font1};
            font-weight: 600;
            font-size: 20px;
            text-align: center;
            color: ${({ theme }) => theme.colors.white};
        }

        > .code-verified-status {
            font-family: ${({ theme }) => theme.typography.font2};
            margin: 0.8rem 0 0 0;
            font-style: normal;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            letter-spacing: 0.002em;
            color: ${({ theme }) => theme.colors.white};
        }

        > h5 {
            font-family: ${({ theme }) => theme.typography.font1};
            text-align: center;
            font-size: 1.25rem;
            font-weight: 600;
        }

        > .code-text {
            font-family: ${({ theme }) => theme.typography.font2};
            text-align: center;
            font-weight: 400;
            font-size: 16px;
        }

        > .verification-code-input {
            padding: 20px 38px;
            width: 171px;
            height: 62px;
            background: ${({ theme }) => theme.backgrounds.stroke};
            border: 2px solid ${({ theme }) => theme.colors.white};
            border-radius: 32px;
            color: ${({ theme }) => theme.colors.white};
            font-family: ${({ theme }) => theme.typography.font1};
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
        }

        > .error-input {
            border: 2px solid ${({ theme }) => theme.colors.orange};
        }

        > .verification-code-input::placeholder {
            font-family: ${({ theme }) => theme.typography.font1};
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: ${({ theme }) => theme.colors.darkGrey};
        }

        > .verification-code-input:focus {
            outline: none;
            border: 2px solid ${({ theme }) => theme.colors.turquoise};
        }

        > .error-input:focus {
            border: 2px solid ${({ theme }) => theme.colors.orange};
        }

        > .error-container {
            text-align: center;
            margin: 1rem 0px;
            > .code-error {
                color: ${({ theme }) => theme.colors.orange};
                font-size: 1rem;
                font-family: ${({ theme }) => theme.typography.font2};
                font-weight: 400;
                letter-spacing: 0.002em;
                padding: 0;
                margin: 0 0 0.5rem 0;
            }

            > .code-error-message {
                font-family: ${({ theme }) => theme.typography.font2};
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                line-height: 26px;
                color: #878ea0;
                padding: 0;
                margin: 0;
            }
        }
    }

    .authpin-wrapper {
        margin-top: 75px;
        width: 100%;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.midTeal};
        padding: 2rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h5 {
            font-family: ${({ theme }) => theme.typography.font1};
            text-align: center;
            font-size: 1.25rem;
            font-weight: 600;
        }

        .verification-code-input {
            margin-top: 25px;
            padding: 20px 36px;
            width: 171px;
            height: 62px;
            background: ${({ theme }) => theme.backgrounds.stroke};
            border: 2px solid ${({ theme }) => theme.colors.white};
            border-radius: 32px;
            color: ${({ theme }) => theme.colors.white};
            font-family: ${({ theme }) => theme.typography.font1};
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;

            &::placeholder {
                font-family: ${({ theme }) => theme.typography.font1};
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: ${({ theme }) => theme.colors.darkGrey};
            }

            &:focus {
                outline: none;
                border: 2px solid ${({ theme }) => theme.colors.turquoise};
            }
        }

        .error-input {
            border: 2px solid ${({ theme }) => theme.colors.orange};

            &:focus {
                border: 2px solid ${({ theme }) => theme.colors.orange};
            }
        }

        .error-container {
            text-align: center;
            margin: 1rem 0px;

            .code-error {
                color: ${({ theme }) => theme.colors.orange};
                font-size: 1rem;
                font-family: ${({ theme }) => theme.typography.font2};
                font-weight: 400;
                letter-spacing: 0.002em;
                padding: 0;
                margin: 0 0 0.5rem 0;
            }

            .code-error-message {
                font-family: ${({ theme }) => theme.typography.font2};
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                line-height: 26px;
                color: #878ea0;
                padding: 0;
                margin: 0;
            }
        }
    }
`;
