import { css } from 'styled-components';

export const bottomSheetStyle = css`
    .no-container {
        padding: 0;

        .backdrop {
            position: fixed;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background: ${({ theme }) => theme.colors.lightTeal};
            opacity: 0.7;
        }

        .exit-card-container {
            position: fixed;
            bottom: 0;
            // height: 16.625rem; // for more than 1 button
            /*height: 7.625rem; // for single button */
            width: 100%;
            background: ${({ theme }) => theme.colors.lightTeal};
            border-radius: 1.875rem 1.875rem 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: space-around; // for more than 1 button
            /*justify-content: space-evenly; // for single button */
            left: 0;
            .notch {
                height: 0.375rem;
                width: 3.75rem;
                background: ${({ theme }) => theme.colors.white};
                opacity: 16%;
                border-radius: 0.188rem;
            }
        }
        .exit-controller-container {
            position: fixed;
            bottom: 0;
            min-height: 7.625rem;
            max-height: 20rem;
            width: 100%;
            background: ${({ theme }) => theme.colors.lightTeal};
            border-radius: 1.875rem 1.875rem 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            left: 0;
            .notch {
                height: 0.375rem;
                width: 3.75rem;
                background: ${({ theme }) => theme.colors.white};
                opacity: 16%;
                border-radius: 0.188rem;
            }
        }

        .settings-card-container {
            position: absolute;
            bottom: 0;
            height: 12.75rem;
            width: 100%;
            background: ${({ theme }) => theme.backgrounds.bg300};
            border-radius: 1.875rem 1.875rem 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            left: 0;
            .notch {
                height: 0.375rem;
                width: 3.75rem;
                background: ${({ theme }) => theme.colors.white};
                opacity: 16%;
                border-radius: 0.188rem;
            }

            .heading-text {
                font: normal ${({ theme }) => theme.typography.fontWeight[600]} 1.125rem/1.375rem
                    ${({ theme }) => theme.typography.font1};
                color: ${({ theme }) => theme.colors.white};
                letter-spacing: 0.005em;
            }

            .align-start {
                align-self: start;
                margin-left: 5%;
            }
        }

        .avatar-container {
            height: 4.75rem;
            width: 100vw;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            .avatar-image {
                height: 3.125rem;
                width: 3.125rem;
                border-radius: 50%;
                background: ${({ theme }) => theme.backgrounds.avatarBg};
                margin: 0 3% 0 5%;
            }

            .avatar-text {
                font: normal ${({ theme }) => theme.typography.fontWeight[600]} 1.125rem/1.375rem
                    ${({ theme }) => theme.typography.font1};
                color: ${({ theme }) => theme.colors.white};
                letter-spacing: 0.005em;
            }
        }

        .exit-confirm-container {
            position: absolute;
            bottom: 0;
            height: 29.5rem;
            width: 100%;
            background: ${({ theme }) => theme.backgrounds.bg300};
            border-radius: 1.875rem 1.875rem 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            left: 0;
            .notch {
                height: 0.375rem;
                width: 3.75rem;
                background: ${({ theme }) => theme.colors.white};
                opacity: 16%;
                border-radius: 0.188rem;
            }

            .exit-confirm-text {
                font: normal ${({ theme }) => theme.typography.fontWeight[400]} 1rem/1.625rem
                    ${({ theme }) => theme.typography.font2};
                color: ${({ theme }) => theme.colors.white};
                letter-spacing: 0.002em;
                margin-left: 4%;
            }
        }

        .participants-container {
            position: fixed;
            bottom: 0;
            min-height: 16.563rem;
            width: 100%;
            background: ${({ theme }) => theme.colors.lightTeal};
            border-radius: 1.875rem 1.875rem 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            left: 0;
            height: 100%;

            .participants-inner-container {
                max-width: 59.75rem;
                width: 100%;
                height: 100%;
            }

            .notch-container {
                align-self: stretch;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 1rem;

                .notch {
                    height: 0.375rem;
                    width: 3.75rem;
                    background: ${({ theme }) => theme.colors.white};
                    opacity: 16%;
                    border-radius: 0.188rem;
                }
            }

            .mid-container {
                align-self: stretch;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 0.875rem 1rem;

                @media (max-width: 768px) {
                    padding: 1rem 1.5rem;
                }

                .transform {
                    height: 1.375rem;
                    width: 1.375rem;
                    transform: rotate(45deg);
                }

                .participant-text {
                    font: normal ${({ theme }) => theme.typography.fontWeight[400]} 1.125rem/1.375rem
                        ${({ theme }) => theme.typography.font1};
                    color: ${({ theme }) => theme.colors.offWhite};
                    letter-spacing: 0.005em;
                }
            }

            .participants-list {
                align-self: stretch;
                overflow: auto;
                height: 100%;
                // padding: 0rem 4rem;
                max-height: calc(60vh - 96px);

                @media (max-width: 768px) {
                    padding: 0rem 1.5rem;
                }

                .loading {
                    color: ${({ theme }) => theme.colors.white};
                }
                .user-container {
                    transition: 2s;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0.75rem;

                    .parent {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        // padding: 1rem 0rem;
                        border-radius: 0.5rem;

                        .participant-portal-icon {
                            height: 3.125rem;
                            width: 3.125rem;
                            background-color: ${({ theme }) => theme.colors.midTeal};
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            > svg {
                                color: ${({ theme }) => theme.colors.midTeal};
                                height: 1.8rem;
                                width: 1.8rem;
                            }
                        }

                        .avatar {
                            height: 3.125rem;
                            width: 3.125rem;
                            border-radius: 50%;
                            background: ${({ theme }) => theme.backgrounds.avatarBg};
                        }

                        > .text-container {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            margin: 0 0.75rem;
                            min-width: 15.813rem;
                            height: 3.25rem;

                            > .text-inner {
                                align-self: stretch;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                padding: 0 0 0.25rem;

                                > .text-in {
                                    font: normal ${({ theme }) => theme.typography.fontWeight[400]} 1.125rem/1.375rem
                                        ${({ theme }) => theme.typography.font1};
                                    color: ${({ theme }) => theme.colors.offWhite};
                                    letter-spacing: 0.005em;
                                }
                            }
                        }

                        .transform {
                            margin: 0 0.75rem;
                            transform: rotate(-90deg);
                            align-self: center;
                            transition: 0.5s;
                        }

                        .rotate {
                            transform: rotate(90deg);
                            transition: 0.5s;

                            &::before {
                                color: ${({ theme }) => theme.colors.turquoise};
                            }
                        }
                    }
                    .child {
                        display: none;
                        &.show {
                            display: flex;
                            flex-direction: column;
                            align-self: flex-start;
                            width: 100%;
                            background: ${({ theme }) => theme.backgrounds.bg400};
                            max-height: 9.5rem;
                            overflow: scroll;

                            .child-container {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                padding: 1.5rem 0 0 2rem;
                                border-radius: 0.5rem;

                                .avatar {
                                    height: 3.125rem;
                                    width: 3.125rem;
                                    border-radius: 50%;
                                    background: ${({ theme }) => theme.backgrounds.avatarBg};
                                }

                                > .text-container {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: flex-start;
                                    margin: 0 0.75rem;
                                    width: 15.563rem;
                                    height: 3.25rem;

                                    > .text-inner {
                                        align-self: stretch;
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        padding: 0 0 0.25rem;

                                        > .text-in {
                                            font: normal ${({ theme }) => theme.typography.fontWeight[400]} 1.125rem/1.375rem
                                                ${({ theme }) => theme.typography.font1};
                                            color: ${({ theme }) => theme.colors.offWhite};
                                            letter-spacing: 0.005em;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .remove-participant-btn {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.danger};
        margin: 0px 0.5rem;
        font-size: 1rem;

        & line {
            stroke: ${({ theme }) => theme.colors.white};
        }
    }
`;
