import { PORTAL_BOOKED } from './constants';

export const isEmpty = (value) => {
    return (
        value == null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
};

export const updatePortalStatus = (portals) => {
    return portals.map((portal) => {
        if (portal?.connectionStatus === PORTAL_BOOKED) {
            return { ...portal, connectionStatus: 'online' };
        }
        return portal;
    });
};

export const getFeatureFlagObj = ({ featureFlags }) => {
    const featureObject = {};
    // Check if featureFlags is not undefined before iterating
    if (featureFlags) {
        featureFlags.forEach((flag) => {
            featureObject[flag.feature] = flag.enabled;
        });
    }
    return featureObject;
};
