import React, { useEffect, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import Loader from './components/loader';
import { store } from './redux-toolkit/store/configure';
import { setUser } from './redux-toolkit/reducer/userReducer';
import { ErrorBoundary } from './components/ErrorBoundary';
import { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/globalStyledComponent';
import { theme } from './styles/theme';
// import { useWebsocketController } from './utils';

sessionStorage.setItem('notifications', JSON.stringify([]));

// Containers
const Layout = React.lazy(() => import('./containers/Layout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const App = () => {
    const [isNetworkAvailable, setIsNetworkAvailable] = useState(true);
    useEffect(() => {
        store.dispatch(setUser({ orgId: process.env.REACT_APP_DEFAULT_ORG_ID }));
        sessionStorage.setItem('user', JSON.stringify({ orgId: process.env.REACT_APP_DEFAULT_ORG_ID }));
        // store.dispatch(getOrgDetailAPI({ orgId:  process.env.REACT_APP_DEFAULT_ORG_ID }));
        /* store.dispatch(setUser({ orgId: process.env.REACT_APP_DEFAULT_ORG_ID }));
        sessionStorage.setItem("user", JSON.stringify({ orgId: process.env.REACT_APP_DEFAULT_ORG_ID })); */
        /* store.dispatch(setUser({ orgId: "sharedstudios_dev" }));
        sessionStorage.setItem("user", JSON.stringify({ orgId: "sharedstudios_dev" })); */

        if (!navigator.onLine) {
            setIsNetworkAvailable(false);
        }
    }, []);
    window.addEventListener('beforeunload', () => {
        sessionStorage.removeItem('flagWSCalled');
        sessionStorage.removeItem('wsConnectionEstablishedAt');
    });

    window.addEventListener('online', (event) => {
        setIsNetworkAvailable(true);
    });

    window.addEventListener('offline', (event) => {
        setIsNetworkAvailable(false);
    });

    // const { userObj } = useSelector(
    //     ({ user }) => ({
    //         userObj: user.userObj,
    //     }),
    //     _.isEqual,
    // );

    /* useEffect(() => {
        const date = new Date();
        const date1 =
            date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds() + "-" + date.getMilliseconds();
        console.log("Time: ", date1, "/", "userObj: ", userObj);
    }, [userObj]); */

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <BrowserRouter>
                    <Suspense fallback={<Loader header={''} content={''}></Loader>}>
                        <ErrorBoundary>
                            <Routes>
                                <Route exact path="/login" name="Login Page" element={<Login />} />
                                {/* <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
                                <Route path="*" name="Start" element={<Layout />} />
                            </Routes>
                        </ErrorBoundary>
                    </Suspense>
                    {/* <AppServiceUpdater /> */}
                    {/* {userObj?.orgId && userObj?.portalId && isEmpty(sessionStorage.getItem('flagWSCalled')) && (
                        // <WSConnection userObj={userObj}></WSConnection>
                    )} */}
                    {!isNetworkAvailable && (
                        <>
                            <div className="no-network">
                                <div user-select="none" className="content-section">
                                    No internet connection.
                                    <br /> Please check your network settings.
                                </div>
                            </div>
                        </>
                    )}
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    );
};

export default App;
