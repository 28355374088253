import { useSelector, useDispatch } from 'react-redux';
import { setPortalList } from '../redux-toolkit/reducer/portalListReducer';
import { useEffect } from 'react';
import _ from 'lodash';
import { PORTAL_OFFLINE } from '../utils/constants';

export default function useAblyPresenceHook() {
    const dispatch = useDispatch();
    // recieve list of all portals
    const { portalListRaw, presenceData } = useSelector(
        ({ portalList, ably }) => ({
            portalListRaw: portalList.portalListRaw.data,
            presenceData: ably?.presenceData,
        }),
        _.isEqual,
    );

    useEffect(() => {
        if (portalListRaw) {
            const portalList = portalListRaw.reduce((acc, portal) => {
                const presencePortal = presenceData.find((_portal) => _portal?.clientId === portal.portal_id);
                const presencePortalStatus = presencePortal?.data?.data?.status || presencePortal?.data?.status;

                if (portal.hasOwnProperty('finalPortalStatus')) {
                    const data = {
                        ...portal,
                        connectionStatus: presencePortalStatus ?? PORTAL_OFFLINE,
                    };
                    acc.push(data);
                }
                return acc;
            }, []);
            dispatch(setPortalList(portalList));
        }
    }, [portalListRaw, presenceData, dispatch]);

    return { presenceData };
}
