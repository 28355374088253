import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { declineInviteAPI } from '../../api/invitePortalToSessionAPI';
import { scannedQRConnectionCallAPI } from '../../api/scannedQRConnectionCallAPI';
import { pollInvitiationAPI } from '../../api/pollInvitiationAPI';
import { fetchParticipantsAPI } from '../../api/fetchParticipantsAPI';
import { cancelConnectionAPI } from '../../api/cancelConnectionAPI';
import { endConnectionAPI } from '../../api/endConnectionAPI';
import { pollSessionStatusAPI } from '../../api/pollSessionStatusAPI';
import extendSessionDurationAPI from '../../api/extendSessionDurationAPI';
import { fetchTimeStampConnectionApi } from '../../api/getConnectionSheduleApi';
import checkSessionExtensionAPI from '../../api/checkSessionExtensionAPI';
import getOngoingSessionByOrgIdPortalIdAPI from '../../api/getOngoingSessionByOrgIdPortalIdAPI';
import { removeParticipantAPI } from '../../api/removeParticipantAPI';
import { createVerificationCodeAPI } from '../../api/createVerificationCodeAPI';
import { validateVerificationCodeAPI } from '../../api/validateVerificationCodeAPI';
import { validateBookingVerificationCodeAPI } from '../../api/validateBookingVerificationCode';
import { createSessionAndInvitePortalAPI } from '../../api/createSessionAndInvitePortal';
import { scannedQRConnectionJoinSessionAPI } from '../../api/scannedQRConnectionJoinSessionAPI';
import { joinDeclinedInviteAPI } from '../../api/joinDeclinedInviteAPI';
import { getDeclinedInvitationAPI } from '../../api/getDeclinedInvitationAPI';
import { authPinVerificationAPI } from '../../api/authPinVerificationAPI';

const initialState = {
    loading: false,
    participantLoading: false,
    error: false,
    errorObj: {},
    connection: {},
    connectionCreated: {},
    connectionCreatedViaJoinSession: {},
    connectionDeleted: {},
    connectionEnded: {},
    invitedPortal: {},
    invitations: [],
    participantsList: [],
    remoteParticipantList: [],
    sessionId: null,
    inviterPortal: {},
    pollSession: {},
    extendSession: {},
    connectionSchedule: [],
    directConnections: [],
    extendLoading: false,
    extendError: false,
    checkSessionAvailableForExtension: {},
    checkSessionExtensionLoading: false,
    checkSessionExtensionError: false,
    getOngoingSession: {},
    getOngoingSessionLoading: false,
    getOngoingSessionError: false,
    getOngoingSessionErrorObj: {},
    endBookedConnection: false,
    portalLeftConnection: {},
    portalLeftConnectionLoading: false,
    portalLeftConnectionError: false,
    portalLeftConnectionErrorObj: {},
    recStartedBy: '',
    isRecOngoing: false,
    // states related to create-verification-code
    loadingCreateVerificationCode: false,
    createVerificationCodeRes: {},
    createVerificationCodeErr: {},
    connectionType: null,
    // states related to validate-verification-code
    loadingValidateVerificationCode: false,
    loadingBookingValidateVerificationCode: false,
    validateVerificationCodeRes: {},
    validateVerificationCodeErr: {},
    validateBookingVerificationCodeResonse: {},
    validateBookingVerificationCodeError: {},
    joinDeclinedInviteRes: {},
    declinedInvitation: {},
    declinedInvitationRaw: {},
    getDeclineLoading: false,
    loadingValidateAuthenticationPin: false,
    validateAuthenticationPinRes: {},
    validateAuthenticationPinErr: '',
    redirectedPortalId: sessionStorage.getItem('redirectedPortalId')
        ? sessionStorage.getItem('redirectedPortalId')
        : null,
};

export const createConnection = createAsyncThunk(
    'connection/createConnection',
    async ({ orgId, portalId, selectedPortal, duration }, { getState }) => {
        const state = getState();
        const securityAuthToken = state.localPortal?.securityAuthToken;
        return await createSessionAndInvitePortalAPI({ orgId, portalId, selectedPortal, securityAuthToken, duration });
    },
);

export const scannedQRConnectionCall = createAsyncThunk('connection/scannedQRConnectionCall', async (orgPortal) => {
    return await scannedQRConnectionCallAPI(orgPortal);
});

export const scannedQRConnectionJoinsSessionCall = createAsyncThunk(
    'connection/scannedQRConnectionJoinSessionCall',
    async (orgPortal) => {
        return await scannedQRConnectionJoinSessionAPI(orgPortal);
    },
);

export const declineInvite = createAsyncThunk('connection/declineInvite', async (declineInvite) => {
    return await declineInviteAPI(declineInvite);
});

export const joinDeclineInvite = createAsyncThunk('connection/joinDeclineInvite', async (joinDeclineInvite) => {
    return await joinDeclinedInviteAPI(joinDeclineInvite);
});

export const pollInvitiation = createAsyncThunk('connection/pollInvitiation', async (invitations) => {
    return await pollInvitiationAPI(invitations);
});

export const fetchParticipants = createAsyncThunk('connection/fetchParticipants', async (org) => {
    return await fetchParticipantsAPI(org);
});

export const cancelConnection = createAsyncThunk('connection/cancelConnectionAPI', async (obj) => {
    return await cancelConnectionAPI(obj);
});

export const endConnection = createAsyncThunk('connection/endConnectionAPI', async (obj) => {
    return await endConnectionAPI(obj);
});

export const pollSessionStatus = createAsyncThunk('connection/pollSessionStatusAPI', async (obj) => {
    return await pollSessionStatusAPI(obj);
});

export const fetchConnectionByTimeStamp = createAsyncThunk('connection/getPortalSessions', async (obj) => {
    return await fetchTimeStampConnectionApi(obj);
});

export const extendSessionDuration = createAsyncThunk(
    'connection/extendSessionDurationAPI',
    async ({ sessionId, orgId }, { rejectWithValue }) => {
        try {
            return await extendSessionDurationAPI(sessionId, orgId);
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const getDeclinedInvitation = createAsyncThunk('connection/getDeclinedInvitation', async (declineInvite) => {
    return await getDeclinedInvitationAPI(declineInvite);
});

export const checkSessionExtension = createAsyncThunk(
    'connection/checkSessionExtension',
    async ({ sessionId, orgId }, { rejectWithValue }) => {
        try {
            return await checkSessionExtensionAPI(sessionId, orgId);
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const getOngoingSessionOfPortal = createAsyncThunk('connection/getOngoingSessionOfPortal', async (obj) => {
    return await getOngoingSessionByOrgIdPortalIdAPI(obj.orgId, obj.portalId);
});

export const removeRemoteParticipant = createAsyncThunk('connection/removeRemoteParticipant', async (obj) => {
    return await removeParticipantAPI(obj.pathParams, obj.bodyParams);
});

export const createVerificationCode = createAsyncThunk(
    'connection/createVerificationCode',
    async ({ orgId, portalId, invitedPortalDetails, acceptInvitation, accessingFromControls, sessionId }) => {
        return await createVerificationCodeAPI({
            orgId,
            portalId,
            invitedPortalDetails,
            acceptInvitation,
            accessingFromControls,
            sessionId,
        });
    },
);

// Verfiy Authentication PIN
export const validateAuthPin = createAsyncThunk('connection/validateAuthPin', async ({ orgPortal, authPin }) => {
    return await authPinVerificationAPI(orgPortal, { authPin });
});

export const verificationCodeValidate = createAsyncThunk(
    'connection/validateVerificationCode',
    async ({ orgPortal, verificationCode }) => {
        return await validateVerificationCodeAPI(orgPortal, { verificationCode });
    },
);

export const bookingVerificationCodeValidate = createAsyncThunk(
    'connection/bookingVerificationCodeValidate',
    async ({ orgPortal, verificationCode }) => {
        return await validateBookingVerificationCodeAPI(orgPortal, { verificationCode });
    },
);

const connectionSlice = createSlice({
    name: 'connection',
    initialState,
    reducers: {
        setConnection: (state, action) => {
            state.connection = action.payload;
        },
        setConnectionCreated: (state, action) => {
            state.connectionCreated = action.payload;
        },
        setInvitedPortal: (state, action) => {
            state.invitedPortal = action.payload;
        },
        setSessionId: (state, action) => {
            state.sessionId = action.payload;
        },
        setInviterPortal: (state, action) => {
            state.inviterPortal = action.payload;
        },
        setConnectionDeleted: (state, action) => {
            state.connectionDeleted = action.payload;
            state.checkSessionAvailableForExtension = {};
        },
        setConnectionEnded: (state, action) => {
            state.connectionEnded = action.payload;
            state.checkSessionAvailableForExtension = {};
            state.sessionId = null;
            state.pollSession = {};
            state.connection = {};
        },
        setExtendSession: (state, action) => {
            state.extendSession = action.payload.data;
        },
        setEndBookedConnection: (state, action) => {
            state.endBookedConnection = action.payload.data;
            state.checkSessionAvailableForExtension = {};
        },
        setPortalLeftConnection: (state) => {
            state.portalLeftConnection = {};
            state.portalLeftConnectionLoading = false;
            state.portalLeftConnectionError = false;
            state.portalLeftConnectionErrorObj = {};
            state.checkSessionAvailableForExtension = {};
        },
        setAgentClosed: (state, action) => {
            state.portalLeftConnection = action.payload.data;
            state.connection = {};
            state.connectionEnded = { sessionId: null };
            state.checkSessionAvailableForExtension = {};
            sessionStorage.removeItem('connectionObj');
            state.sessionId = null;
            state.pollSession = {};
        },
        setCheckSessionAvailableForExtension: (state) => {
            state.checkSessionAvailableForExtension = {};
        },
        setConnectionType: (state, action) => {
            state.connectionType = action.payload;
        },
        clearVerificationCodeAndValidateCodeRes: (state, action) => {
            state.createVerificationCodeRes = action.payload;
            state.validateVerificationCodeRes = action.payload;
            state.validateBookingVerificationCodeResonse = action.payload;
        },
        clearJoinDeclinedInviteRes: (state, action) => {
            state.joinDeclinedInviteRes = action.payload;
        },
        setDeclineInvitation: (state, action) => {
            state.declinedInvitation = action.payload;
        },
        resetAuthPinValidationResponse: (state) => {
            state.validateAuthenticationPinRes = {};
            state.validateAuthenticationPinErr = '';
            state.loadingValidateAuthenticationPin = false;
        },
        setRedirectedPortalId: (state, action) => {
            sessionStorage.setItem('redirectedPortalId', action.payload);
            state.redirectedPortalId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createConnection.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
            })
            .addCase(createConnection.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (action.payload?.data) {
                    state.connection = action.payload.data;
                    sessionStorage.setItem('connectionObj', JSON.stringify(action.payload.data));
                } else {
                    state.connection = {};
                    state.connectionCreated = {};
                    state.error = true;
                }
                state.connectionEnded = {};
                state.connectionDeleted = {};
            })
            .addCase(createConnection.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
                state.connectionCreated = {};
                state.connectionEnded = {};
                state.connectionDeleted = {};
                state.checkSessionAvailableForExtension = {};
            })
            .addCase(scannedQRConnectionCall.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
            })
            .addCase(scannedQRConnectionCall.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (action.payload?.data) {
                    state.connectionCreated = action.payload.data;
                    sessionStorage.setItem('connectionObj', JSON.stringify(action.payload.data));
                } else {
                    state.connectionCreated = {};
                    state.error = true;
                }
                state.connectionEnded = {};
                state.connectionDeleted = {};
                state.checkSessionAvailableForExtension = {};
            })
            .addCase(scannedQRConnectionCall.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
                state.connectionCreated = {};
                state.connectionEnded = {};
                state.checkSessionAvailableForExtension = {};
                state.connectionDeleted = {};
            })

            .addCase(scannedQRConnectionJoinsSessionCall.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(scannedQRConnectionJoinsSessionCall.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (action.payload?.data) {
                    state.connectionCreatedViaJoinSession = action.payload.data;
                } else {
                    state.connectionCreatedViaJoinSession = {};
                    state.error = true;
                }
                state.connectionEnded = {};
                state.connectionDeleted = {};
                state.checkSessionAvailableForExtension = {};
            })
            .addCase(scannedQRConnectionJoinsSessionCall.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
                state.connectionCreatedViaJoinSession = {};
                state.connectionEnded = {};
                state.checkSessionAvailableForExtension = {};
                state.connectionDeleted = {};
            })
            .addCase(declineInvite.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(declineInvite.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (action.payload?.data) {
                    state.invitations = action.payload.data;
                } else {
                    state.invitations = [];
                    state.error = true;
                }
            })
            .addCase(declineInvite.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            })
            .addCase(pollInvitiation.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(pollInvitiation.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (action.payload?.data) {
                    state.invitations = action.payload.data;
                } else {
                    state.invitations = [];
                    state.error = true;
                }
            })
            .addCase(pollInvitiation.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            })
            .addCase(fetchParticipants.pending, (state) => {
                // action is inferred correctly here if using TS
                state.participantLoading = true;
                state.error = false;
            })
            .addCase(fetchParticipants.fulfilled, (state, action) => {
                state.error = false;
                if (action.payload?.data) {
                    state.participantsList = action.payload.data.data;
                    state.remoteParticipantList = action.payload.data.remoteParticipants.data;
                } else {
                    state.participantsList = [];
                    state.error = true;
                }
                state.participantLoading = false;
            })
            .addCase(fetchParticipants.rejected, (state, action) => {
                state.participantLoading = false;
                state.error = true;
                state.errorObj = action.error;
                state.participantsList = {};
                state.connectionEnded = {};
            })
            .addCase(endConnection.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
            })
            .addCase(endConnection.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.connection = {};
                state.connectionEnded = { sessionId: null };
                sessionStorage.removeItem('connectionObj');
                state.sessionId = null;
                state.pollSession = {};
            })
            .addCase(endConnection.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            })
            .addCase(cancelConnection.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
            })
            .addCase(cancelConnection.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.connection = {};
                state.connectionDeleted = { sessionId: null };
                state.connectionEnded = {};
                state.checkSessionAvailableForExtension = {};
                sessionStorage.removeItem('connectionObj');
                state.sessionId = null;
                state.pollSession = {};
            })
            .addCase(cancelConnection.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            })

            .addCase(pollSessionStatus.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
            })
            .addCase(pollSessionStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.pollSession = {};
                if (action.payload?.data) {
                    state.pollSession = action.payload.data;
                    state.recStartedBy = action.payload.data.session.recordingStartedBy;
                    state.isRecOngoing = action.payload.data.session.isRecordingOnGoing;
                }
            })
            .addCase(pollSessionStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            })

            .addCase(extendSessionDuration.pending, (state) => {
                state.extendLoading = true;
                state.extendError = false;
            })
            .addCase(extendSessionDuration.fulfilled, (state, action) => {
                state.extendLoading = false;
                state.extendError = false;
                state.extendSession = action.payload.data ?? {};
            })
            .addCase(extendSessionDuration.rejected, (state, action) => {
                state.extendLoading = false;
                state.extendError = true;
                state.extendSession = action.payload ?? {};
            })
            .addCase(checkSessionExtension.pending, (state) => {
                state.checkSessionExtensionLoading = true;
                state.checkSessionExtensionError = false;
            })
            .addCase(checkSessionExtension.fulfilled, (state, action) => {
                state.checkSessionExtensionLoading = false;
                state.checkSessionExtensionError = false;
                state.checkSessionAvailableForExtension = action.payload.data ?? {};
            })
            .addCase(checkSessionExtension.rejected, (state, action) => {
                state.checkSessionExtensionLoading = false;
                state.checkSessionExtensionError = false;
                state.checkSessionAvailableForExtension = {
                    canBeExtended: action?.payload?.canBeExtended,
                    overlappingSessions: Array(0),
                };
            })

            .addCase(getOngoingSessionOfPortal.pending, (state) => {
                state.getOngoingSessionLoading = true;
                state.getOngoingSessionError = false;
            })
            .addCase(getOngoingSessionOfPortal.fulfilled, (state, action) => {
                state.getOngoingSessionLoading = false;
                state.getOngoingSessionError = false;
                state.getOngoingSession = action.payload.data ?? {};
                state.sessionId = action.payload.data.sessionId ?? null;
            })
            .addCase(getOngoingSessionOfPortal.rejected, (state, action) => {
                state.getOngoingSessionLoading = false;
                state.getOngoingSessionError = true;
                state.getOngoingSessionErrorObj = action.error;
            })

            .addCase(fetchConnectionByTimeStamp.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchConnectionByTimeStamp.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.connectionSchedule = [];
                if (action.payload?.data?.result?.Items) {
                    const tempList = [];
                    const directConnections = [];
                    action.payload.data.result.Items.forEach((item) => {
                        item.noCache = new Date().getTime();
                        if (item.isCreatedFromBooking) {
                            tempList.push(item);
                        } else {
                            directConnections.push(item);
                        }
                    });
                    state.connectionSchedule = [...tempList];
                    state.directConnections = directConnections;
                }
            })
            .addCase(fetchConnectionByTimeStamp.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            })
            .addCase(removeRemoteParticipant.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(removeRemoteParticipant.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                const { sent, removedParticipant } = action.payload.data;
                if (sent) {
                    const filterdRemoteParticipants = state.remoteParticipantList.filter(
                        (participant) => participant.id !== removedParticipant,
                    );
                    state.remoteParticipantList = filterdRemoteParticipants;
                    state.error = true;
                } else {
                    state.errorObj = { message: 'error removing participant' };
                }
            })
            .addCase(removeRemoteParticipant.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            })
            // createVerificationCode set response
            .addCase(createVerificationCode.pending, (state) => {
                state.loadingCreateVerificationCode = true;
                state.createVerificationCodeErr = {};
            })
            .addCase(createVerificationCode.fulfilled, (state, action) => {
                state.loadingCreateVerificationCode = false;
                state.createVerificationCodeRes = action.payload.data;
            })
            .addCase(createVerificationCode.rejected, (state, action) => {
                state.loadingCreateVerificationCode = false;
                state.createVerificationCodeErr = action.error;
            })
            // validateAuthenticationPin set response
            .addCase(validateAuthPin.pending, (state) => {
                state.loadingValidateAuthenticationPin = true;
                state.validateAuthenticationPinErr = '';
            })
            .addCase(validateAuthPin.fulfilled, (state, action) => {
                state.loadingValidateAuthenticationPin = false;
                state.validateAuthenticationPinRes = action?.payload;
                state.validateAuthenticationPinErr = '';
            })
            .addCase(validateAuthPin.rejected, (state, action) => {
                state.loadingValidateAuthenticationPin = false;
                state.validateAuthenticationPinErr = action.error.message;
            })
            // validateVerificationCode set response
            .addCase(verificationCodeValidate.pending, (state) => {
                state.loadingValidateVerificationCode = true;
                state.validateVerificationCodeErr = {};
            })
            .addCase(verificationCodeValidate.fulfilled, (state, action) => {
                state.loadingValidateVerificationCode = false;
                state.validateVerificationCodeRes = action?.payload;
            })
            .addCase(verificationCodeValidate.rejected, (state, action) => {
                state.loadingValidateVerificationCode = false;
                state.validateVerificationCodeErr = action.error;
            })
            // validateBookingVerification set response
            .addCase(bookingVerificationCodeValidate.pending, (state) => {
                state.loadingBookingValidateVerificationCode = true;
                state.validateBookingVerificationCodeError = {};
            })
            .addCase(bookingVerificationCodeValidate.fulfilled, (state, action) => {
                state.loadingBookingValidateVerificationCode = false;
                state.validateBookingVerificationCodeResonse = action?.payload;
            })
            .addCase(bookingVerificationCodeValidate.rejected, (state, action) => {
                state.loadingBookingValidateVerificationCode = false;
                state.validateBookingVerificationCodeError = action.error;
            })
            .addCase(joinDeclineInvite.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(joinDeclineInvite.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.joinDeclinedInviteRes = action.payload.data;
            })
            .addCase(joinDeclineInvite.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            })
            .addCase(getDeclinedInvitation.pending, (state) => {
                state.getDeclineLoading = true;
                state.error = false;
            })
            .addCase(getDeclinedInvitation.fulfilled, (state, action) => {
                state.getDeclineLoading = false;
                state.error = false;
                if (action.payload?.data) {
                    state.declinedInvitationRaw = action.payload.data;
                } else {
                    state.declinedInvitationRaw = {};
                    state.error = true;
                }
            })
            .addCase(getDeclinedInvitation.rejected, (state, action) => {
                state.getDeclineLoading = false;
                state.error = true;
                state.errorObj = action.error;
            });
    },
});

export const {
    setConnection,
    setConnectionCreated,
    setInvitedPortal,
    setSessionId,
    setInviterPortal,
    setConnectionDeleted,
    setConnectionEnded,
    setExtendSession,
    setEndBookedConnection,
    setPortalLeftConnection,
    setAgentClosed,
    setCheckSessionAvailableForExtension,
    setConnectionType,
    clearVerificationCodeAndValidateCodeRes,
    clearJoinDeclinedInviteRes,
    setDeclineInvitation,
    resetAuthPinValidationResponse,
    setRedirectedPortalId,
} = connectionSlice.actions;
export default connectionSlice.reducer;
