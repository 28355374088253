import { css } from 'styled-components';

export const avatarStyles = css`
    .user-avatar-initials {
        position: absolute;
        height: 100%;
        width: 100%;
        background: ${({ theme }) => theme.colors.lightTeal} !important;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
    }

    .initials {
        width: 72px;
        height: 72px;
        background: ${({ theme }) => theme.colors.turquoise};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    .username-initials {
        margin: 0;
        padding: 0;
        font-family: ${({ theme }) => theme.typography.font1};
        font-style: normal;
        font-weight: 700;
        font-size: 2rem;
        line-height: 29px;
        text-align: center;
        letter-spacing: 0.005em;
        color: ${({ theme }) => theme.colors.white};
    }

    .tray-buttons-container {
        width: max-content;
        height: 64px;
        padding: 10px 1.5rem;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        background: ${({ theme }) => theme.colors.lightTeal};
        border-radius: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .tray-buttons-container > .in-call-action {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background: ${({ theme }) => theme.backgrounds.stroke};
        border: 2px solid ${({ theme }) => theme.backgrounds.bg500};
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tray-buttons-container > .leave {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.orange};
        border: 2px solid ${({ theme }) => theme.backgrounds.bg500};
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
