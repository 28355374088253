import { css } from 'styled-components';

export const inviteParticipantsStyle = css`
    .invite-title {
        font-family: ${({ theme }) => theme.typography.font1};
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.005em;
        color: ${({ theme }) => theme.colors.white};
        margin: 1rem 0px;
    }

    .invite-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 1;
    }

    .invite-container > .email-list {
        flex: 3;
    }

    .email-error {
        color: red;
        font-size: 14px;
        margin: 0.5rem 1rem;
        padding: 0px;
    }

    .email-input {
        max-width: 100%;
        border: 2px solid ${({ theme }) => theme.backgrounds.bg500};
        border-radius: 32px;
        background: ${({ theme }) => theme.backgrounds.stroke};
        display: flex;
        align-items: center;
        padding: 0px 1rem;
        gap: 0.5rem;
        overflow-x: scroll;
    }

    .email-input::-webkit-scrollbar {
        display: none;
    }

    .email-input > input {
        flex: 1;
        background-color: inherit;
        padding: 1rem 0px;
        border: none;
        outline: none;
        font-family: ${({ theme }) => theme.typography.font2};
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.002em;
        color: ${({ theme }) => theme.colors.white};

        /* Webkit autofill pseudo-class for input fields */
        &:-webkit-autofill {
            background-color: inherit !important;
            color: ${({ theme }) => theme.colors.white} !important;
            transition: background-color 5000s ease-in-out 0s;
        }

        &:-webkit-autofill:focus,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:active {
            background-color: inherit !important;
        }
    }

    .email-input > input::placeholder {
        font-family: ${({ theme }) => theme.typography.font2};
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.002em;
        color: ${({ theme }) => theme.colors.darkGrey};
    }

    .entered-emails {
        display: flex;
        gap: 0.5rem;
    }

    .entered-email {
        display: flex;
        gap: 0.5rem;
        padding: 4px 0.5rem 4px 0.5rem;
        align-items: center;
        background: ${({ theme }) => theme.colors.lightTeal};
        border-radius: 24px;
    }

    .entered-email > span {
        font-family: ${({ theme }) => theme.typography.font2};
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.002em;
        color: ${({ theme }) => theme.colors.white};
    }

    .invite-action {
        text-align: center;
        flex: 1;
    }

    .invite-action > button {
        width: 60%;
        font-family: ${({ theme }) => theme.typography.font1};
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.02em;
        color: ${({ theme }) => theme.colors.white};
        border: none;
        outline: none;
        background: ${({ theme }) => theme.colors.turquoise};
        border-radius: 32px;
        padding: 1rem 0px;
    }
`;
