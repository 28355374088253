import { css } from 'styled-components';

export const formStyles = css`
    .form-label {
        color: ${({ theme }) => theme.colors.white};
    }
    select.form-select {
        background: ${({ theme }) => theme.backgrounds.stroke};
        border: 2px solid ${({ theme }) => theme.backgrounds.bg500};
        color: ${({ theme }) => theme.colors.white};
        option {
            background: ${({ theme }) => theme.backgrounds.bg200};
            color: ${({ theme }) => theme.colors.white};
        }
        &.is-invalid {
            background-repeat: no-repeat;
        }
    }
    .form-control {
        background: ${({ theme }) => theme.backgrounds.stroke};
        border: 2px solid ${({ theme }) => theme.backgrounds.bg500};
        color: ${({ theme }) => theme.colors.white};
        /* width: 21.438rem; */
        height: 4rem;
        border-radius: 2rem;
        padding-left: 1.4rem;
        font: normal ${({ theme }) => theme.typography.fontWeight[400]} 1rem/1.625rem
            ${({ theme }) => theme.typography.font2} !important;
        letter-spacing: 0.002em !important;
        &:hover,
        &:focus {
            border: 2px solid ${({ theme }) => theme.colors.white};
            color: ${({ theme }) => theme.colors.white};
            box-shadow: none;
        }
        &.is-invalid {
            border: 2px solid ${({ theme }) => theme.colors.coral};
            background-size: calc(0.35em + 2.6rem) calc(1.25em + 0.275rem);
            color: ${({ theme }) => theme.colors.white};
            background-image: url('../assets/imgs/error.svg');
            &:hover,
            &:focus {
                border: 2px solid ${({ theme }) => theme.colors.coral};
                background: ${({ theme }) => theme.backgrounds.stroke};
                box-shadow: none;
            }
        }
        &.is-valid {
            border: 2px solid ${({ theme }) => theme.colors.green};
            background-size: calc(0.35em + 2.6rem) calc(1.25em + 0.275rem);
            color: ${({ theme }) => theme.colors.white};
            background-image: url('../assets/imgs/checked.svg');
            &:hover,
            &:focus {
                border: 2px solid ${({ theme }) => theme.colors.green};
                background: ${({ theme }) => theme.backgrounds.stroke};
                box-shadow: none;
            }
        }
        &.custom-search-input {
            border: 2px solid ${({ theme }) => theme.backgrounds.bg500};
            background: ${({ theme }) => theme.backgrounds.stroke};
            border-top-right-radius: 2rem !important;
            border-bottom-right-radius: 2rem !important;
            &:hover,
            &:focus {
                border: 2px solid ${({ theme }) => theme.colors.white};
                color: ${({ theme }) => theme.colors.white};
                box-shadow: none;
            }
            &:disabled,
            &:hover,
            &:focus {
                background: transparent !important;
            }
        }
        &:disabled {
            background: ${({ theme }) => theme.colors.lightGrey};
            border: 2px solid ${({ theme }) => theme.colors.lightGrey};
            color: ${({ theme }) => theme.colors.white};
            &:hover,
            &:focus {
                background: ${({ theme }) => theme.colors.lightGrey};
                border: 2px solid ${({ theme }) => theme.colors.lightGrey};
                color: ${({ theme }) => theme.colors.white};
                box-shadow: none;
            }
        }
        &::placeholder {
            color: ${({ theme }) => theme.colors.white};
        }
    }
`;
