const ablyMessages = {
    connectionDuration: {
        updateConnectionDuration: 'connection-duration-update',
    },
    inputOutputConfig: {
        volumeConfig: 'volume-config',
        muteConfig: 'mute-config',
        requireVolumeData: 'require-volume-data',
        micConfig: 'mic-config',
        playSound: 'play-sound',
        volumeStatus: 'volume-status',
        fetchParticipantAndMicStatus: 'fetch-participant-and-mic-status',
    },
    agentScreen: {
        resetAgentToSplashScreen: 'reset-agent-to-splash-screen',
    },
    team: {
        joinMsTeam: 'join-ms-team',
        endMsTeam: 'end-ms-team',
        muteUnmuteMsTeam: 'mute-unmute-ms-team',
        resetIoCheckMsTeam: 'reset-io-check-ms-team',
        initiateMsTeam: 'initiate-ms-team',
        teamCallInitiated: 'team-call-initiated',
        teamCallEnded: 'team-call-ended',
        teamCallInitiationError: 'team-call-initiation-error',
        redirectWaitingScreen: 'redirect-waiting-screen',
        cancelTeamsCallInitiate: 'cancel-teams-call-initiate',
        teamCallParticipant: 'team-call-participant',
        teamCallMicStatus: 'team-call-mic-status',
    },
    screenShare: {
        admitScreenshareParticipant: 'admit-screenshare-participant',
    },
    ablyStatus: {
        updateAblyStatus: 'ably-status-update',
    },
    surfly: {
        surflySessionStart: 'surfly-session-start',
    },
    portal: {
        newPortalRegistered: 'new-portal-registered',
        portalAssignedGroupUpdated: 'portal-assigned-group-updated',
    },
    session: {
        invitedToSession: 'invited-to-session',
        declinedSession: 'declined-session',
        endSession: 'end-session',
    },
    flag: {
        featureFlagUpdated: 'feature-flag-updated',
    },
};

export default ablyMessages;
