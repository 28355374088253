import { css } from 'styled-components';

//* Card styles (_card.scss) *//
export const cardStyles = css`
    .card.card-with-backdrop {
        width: 100%;

        border-radius: 1rem;
        background-color: transparent;
        border: none !important;
        color: ${({ theme }) => theme.colors.offWhite};
        margin-bottom: 1.5rem;

        .card-body {
            padding: 0;
        }

        .main {
            background-color: ${({ theme }) => theme.colors.lightTeal};
            border-radius: 1rem;
            padding: 1.5rem 1.688rem;

            .switch-portal {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 1rem;
                height: 2rem;
                width: 2rem;
                cursor: pointer;

                &:hover,
                &:active {
                    background-color: ${({ theme }) => theme.colors.midTeal};
                }
            }

            &.sm i {
                // position: relative;
                // top: calc(50% - 9px);
            }
        }

        .backdrop {
            position: absolute;
            background-color: ${({ theme }) => theme.backgrounds.bg200};
            height: 1rem;
            width: calc(100% - 2rem);
            left: 1rem;
            border-bottom-right-radius: 1rem;
            border-bottom-left-radius: 1rem;
        }
    }

    .card.card-without-backdrop {
        max-width: 100%;
        width: 21.5rem;
        margin: 0 auto;
        margin-top: 1.875rem !important;
        background-color: ${({ theme }) => theme.backgrounds.bg300};
        border-radius: 1rem;
        border: none !important;
        color: ${({ theme }) => theme.colors.white};

        .card-body {
            padding: 1.875rem 2.188rem;
            text-align: center;
        }
    }
`;
