import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { controlsMuteUnMuteUpdateAPIForAbly } from '../../api/controlsMuteUnMuteUpdateAPI';
import { recordingConfigAPI } from '../../api/recordingConfigAPI';
import { controlsVolumeUpdateAblyAPI } from '../../api/controlsVolumeUpdateAblyAPI';

const initialState = {};

export const updateControlsScreenVolumeAbly = createAsyncThunk(
    'controlsConfig/updateControlsScreenVolumeAbly',
    async ({ orgId, portalId, sysVolume, sessionId }, { rejectWithValue }) => {
        try {
            return await controlsVolumeUpdateAblyAPI(orgId, portalId, sysVolume, sessionId);
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateControlsMuteUnMuteForAbly = createAsyncThunk(
    'controlsConfig/updateControlsMuteUnMuteForAbly',
    async ({ orgId, portalId, isMuted, sessionId }, { rejectWithValue }) => {
        try {
            return await controlsMuteUnMuteUpdateAPIForAbly(orgId, portalId, isMuted, sessionId);
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const recordingConfig = createAsyncThunk(
    'controlsConfig/recordingConfig',
    async ({ orgId, portalId, sessionId, state }, { rejectWithValue }) => {
        try {
            return await recordingConfigAPI(orgId, portalId, sessionId, state);
        } catch (err) {
            if (!err.response) throw err;
            return rejectWithValue(err.response.data);
        }
    },
);

const controlsConfigSlice = createSlice({
    name: 'controlsConfig',
    initialState,
    reducers: {},
});
export default controlsConfigSlice.reducer;
