import { css } from 'styled-components';

export const bookedConnection = css`
    .booked-card {
        width: 100% !important;
        padding: 1.875rem 1.375rem;
        margin-bottom: 3.75rem !important;
        background-color: ${({ theme }) => theme.colors.lightTeal} !important;
    }

    .booked-card > h6 {
        ${({ theme }) => theme.typography.font1};
    }

    .booked-card > p {
        ${({ theme }) => theme.typography.font3};
    }

    .guests {
        margin-top: 1.875rem;
        .guest {
            gap: 0.75rem;

            .avatar {
                width: 1.875rem;
                height: 1.875rem;
                border-radius: 100%;
                object-fit: cover;
            }
        }
    }

    .primary-text {
        color: ${({ theme }) => theme.colors.turquoise};
        text-decoration: underline;
    }

    .btn-primary-outlined:hover {
        border: 2px solid ${({ theme }) => theme.colors.turquoise};
    }

    .portal-info-text {
        margin: 0.25rem 0 0 0;
        padding: 0;
        font-family: ${({ theme }) => theme.typography.font2};
        font-size: 14px;
        text-align: center;
        color: ${({ theme }) => theme.colors.coral};
    }

    .portal-info-light-text {
        color: ${({ theme }) => theme.colors.lightGray};
    }

    .btn-text {
        background-color: transparent;
        border: none;
        outline: none;
    }

    .bookedTimer {
        font-family: ${({ theme }) => theme.typography.font1};
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: 0.005em;
    }
`;
