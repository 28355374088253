import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeclinedInvitation } from '../redux-toolkit/reducer/connectionReducer';
import ablyMessages from '../utils/ablyMessages';

export default function useCmdDeclineSession({ message }) {
    const dispatch = useDispatch();
    const portal = useSelector((state) => state?.portal?.portal);

    const handleDeclineSession = async (commandData) => {
        if (!commandData?.message && portal?.portal_id === commandData?.portal_id) {
            setTimeout(async () => {
                await dispatch(getDeclinedInvitation({ orgId: portal?.org_id, portalId: portal.portal_id }));
            }, 5000);
        }
    };

    useEffect(() => {
        const handleCommand = async () => {
            const command = message?.data?.message || '';
            const commandData = message?.data?.data || {};
            if (command === ablyMessages.session.declinedSession && commandData?.portal_id === portal.portal_id) {
                await handleDeclineSession(commandData);
            }
        };

        handleCommand();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message, dispatch]);
}
