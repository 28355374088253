import { css } from 'styled-components';

export const waitingScreenStyle = css`
    .waiting-main-container {
        display: flex;
        padding: 1.875rem 1.375rem;
        flex-direction: column;
        align-items: center;
        gap: 1.875rem;
        align-self: stretch;
        border-radius: 1rem;
        background: ${({ theme }) => theme.colors.lightTeal};

        p {
            color: ${({ theme }) => theme.colors.offWhite};
            text-align: center;
            font-family: ${({ theme }) => theme.typography.font1};
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.00625rem;
        }

        .heading {
            font-size: 1.25rem;
        }

        .meeting-id {
            color: ${({ theme }) => theme.colors.lightGrey};

            /* body/3 */
            font-family: ${({ theme }) => theme.typography.font3};
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem; /* 150% */
            letter-spacing: 0.002rem;
        }
    }
`;
